<template>
  <div>
    <!-- Email campaign -->
    <div class="email-step1">
      <!-- email-next -->
      <div class="email-next">
        <div class="list-btns">
          <ul>
            <li><a class="active step1" href="javascript:;">Details</a></li>
            <li><a class="step2" href="javascript:;">Customize Template</a></li>
            <li><a class="step3" href="javascript:;">Review</a></li>
            <li><a class="step4" href="javascript:;">Recipients</a></li>
            <span class="bar"></span>
          </ul>
        </div>
      </div>
      <!-- email-next -->

      <div class="email-campaign">
        <!-- step1-area -->
        <div class="step1-area">
          <div class="email-deatils">
            <h3>Campaign Name</h3>
            <form action="">
              <div class="form-row">
                <div class="col-md-12">
                  <div class="form-group">
                    <!-- <Multiselect v-model="multiselectValue" mode="tags" :close-on-select="false" :searchable="true"
                      :create-option="true" :options="multiselectOptions" /> -->
    <!-- :options="async function (query, select$) {
      return await fetchLanguages(query) // check JS block in JSFiddle for implementation
    }" -->
                    <!-- :options="[
    { value: 'batman', label: 'Batman' },
    { value: 'robin', label: 'Robin' },
    { value: 'joker', label: 'Joker' },
  ]" -->
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="col-md-12">
                  <div class="form-group">
                    <input type="text" class="form-control" id="campaign_name" aria-label="Campaign Name"
                      placeholder="Campaign Name" v-model="email_campaign_form.campaign_name" />
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <input type="text" class="form-control" id="email_subject" aria-label="Campaign Subject"
                      placeholder="Campaign Subject" v-model="email_campaign_form.email_subject" />
                  </div>
                </div>
                <!--
                <div class=" col-md-6">
                  <div class="form-group">
                    <input type="text" class="form-control" aria-label="Enater Campaign Name" id="inputAddress24"
                      placeholder="Enater Campaign Name" v-model="email_campaign_form.template_textarea" />
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group ">
                    <input type="text" class="form-control" aria-label="Form Name" id="inputAddress24"
                      placeholder="Form Name" v-model="email_campaign_form.template_textarea" />
                  </div>
                </div>
-->
              </div>


              <!-- email-template -->
              <div class="email-template">
                <div class="form-row">
                  <div class="col-md-4">
                    <div class="lefts">
                      <h3>Choose Template</h3>
                    </div>
                    <div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                      <a class="nav-link" id="v-pills-touch-tab" data-toggle="pill" :href="'#v-pills-touch' + Template.id"
                        role="tab" aria-controls="v-pills-touch" aria-selected="true" v-for="Template in AllTemplates"
                        :key="Template" @click="putTemplateValue(Template.id, Template.template)">{{ Template.title }}</a>
                    </div>
                  </div>
                  <div class="col-md-8">
                    <div class="tab-content" id="v-pills-tabContent">
                      <div class="tab-pane fade show active" role="tabpanel" aria-labelledby="v-pills-touch-tab">
                        <div class="preview" style="margin: 15px; min-height: 350px;">
                        </div>
                      </div>
                      <div class="tab-pane fade" :id="'v-pills-touch' + Template.id" role="tabpanel"
                        aria-labelledby="v-pills-touch-tab" v-for="Template in AllTemplates" :key="Template">
                        <div class="email-boxs">
                          <div class="preview" style="min-height: 350px; margin: 15px; padding: 25px; line-height: 35px;"
                            v-html="Template.template">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- email-template -->
            </form>
          </div>
        </div>
        <!-- step1-area -->

      </div>
      <!-- Email campaign -->
      <!-- next-btns -->
      <div class="next-btns modal-footer email-footer">

        <a href="javascript:;" @click="gotoStep2('next', 'selected')" class="add-btn">Select Template</a>
        <!--<a href="javascript:;" class="rem-btn">Delete Template</a>-->
        <a class="step2" @click="gotoStep2('next')" href="javascript:;">Create New Template</a>
      </div>
      <!-- next-btns -->

    </div>
    <!-- email-setp1 end-->




    <!-- email-step3 -->
    <div class="email-step2">

      <!-- email-next -->
      <div class="email-next">
        <div class="back">
          <a class="step1" href="javascript:;" @click="gotoStep1('prev')"><img class="img-fluid"
              src="@/assets/img/arrow-left.svg" alt="image ttile"> Back</a>
        </div>
        <div class="list-btns">
          <ul>
            <li><a class="step1" href="javascript:;">Details</a></li>
            <li><a class="active step2" href="javascript:;">Customize Template</a></li>
            <li><a class="step3" href="javascript:;">Review</a></li>
            <li><a class="step4" href="javascript:;">Recipients</a></li>
            <span class="bar"></span>
          </ul>
        </div>

      </div>
      <!-- email-next -->

      <div class="email-campaign">

        <!-- step1-area -->
        <div class="step1-area">
          <div class="email-deatils">
            <form action="">
              <div class="form-group">
                <label for="Customize">Customize Template</label>
                <TinyMceEditor v-model="email_campaign_form.campaign_template"
                  api-key="b0a86stci3bma8udzy2emmq8r6csqg497zdv3pg33p1ifmre" :init="{
                    // inital_
                    menubar: false,
                    branding: false,
                    extended_valid_elements: 'span[class|align|style]',
                    forced_root_block_attrs: { 'class': 'root_block_p' },
                    element_format: 'html',
                    relative_urls: false,
                    remove_script_host: false,
                    height: 200,
                    browser_spellcheck: true,
                    verify_html: false,
                    // images_upload_handler: tinyMceUploader,
                    plugins: 'advlist autolink lists link image charmap print preview anchor searchreplace visualblocks code insertdatetime table paste emoticons',
                    toolbar: 'fontselect | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist | link image | forecolor backcolor | emoticons | code'
                  }" />
              </div>
              <div class="variable">
                <p>Variable :</p>
                <ul>
                  <li><a href="javascript:;" @click="variable_inupt($event)">%CustomerName%</a></li>
                  <!-- <li><a href="javascript:;" @click="variable_inupt($event)">%Last-Name%</a></li> -->
                  <li><a href="javascript:;" @click="variable_inupt($event)">%companyname%</a></li>
                  <li><a href="javascript:;" @click="variable_inupt($event)">%companyphone%</a></li>
                  <li><a href="javascript:;" @click="variable_inupt($event)">%companyemail%</a></li>
                  <li><a href="javascript:;" @click="variable_inupt($event)">%companyaddress%</a></li>
                </ul>
              </div>
            </form>
          </div>

        </div>
        <!-- step1-area -->

      </div>
      <!-- Email campaign -->
      <!-- next-btns -->
      <div class="next-btns">
        <a class="step3" @click="gotoStep3('next')" href="javascript:;">next</a>
      </div>
      <!-- next-btns -->

    </div>
    <!-- email-setp3 end-->


    <!-- email-step4 -->
    <div class="email-step3">
      <!-- email-next -->
      <div class="email-next">
        <div class="back">
          <a class="step2" href="javascript:;" @click="gotoStep2('prev')"><img class="img-fluid"
              src="@/assets/img/arrow-left.svg" alt="image ttile"> Back</a>
        </div>
        <div class="list-btns">
          <ul>
            <li><a class="step1" href="javascript:;">Details</a></li>
            <li><a class="step2" href="javascript:;">Customize Template</a></li>
            <li><a class="active step3" href="javascript:;">Review</a></li>
            <li><a class="step4" href="javascript:;">Recipients</a></li>
            <span class="bar"></span>
          </ul>
        </div>

      </div>
      <!-- email-next -->

      <div class="email-campaign">
        <!-- step1-area -->
        <div class="step1-area">
          <div class="email-deatils">

            <div class="preview" v-html="email_campaign_form.campaign_template"
              style="min-height: 350px; margin: 15px; padding: 25px; line-height: 35px;">

            </div>

          </div>

        </div>
        <!-- step1-area -->

      </div>
      <!-- Email campaign -->
      <!-- next-btns -->
      <div class="next-btns">
        <a class="step4" @click="gotoStep4('next')" href="javascript:;">next</a>
      </div>
      <!-- next-btns -->

    </div>
    <!-- email-setp4 end-->


    <!-- email-step5 -->
    <div class="email-step4">

      <!-- email-next -->
      <div class="email-next">
        <div class="back">
          <a class="step3" href="javascript:;" @click="gotoStep3('prev')"><img class="img-fluid"
              src="@/assets/img/arrow-left.svg" alt="image ttile"> Back</a>
        </div>
        <div class="list-btns">
          <ul>
            <li><a class="step1" href="javascript:;">Details</a></li>
            <li><a class="step2" href="javascript:;">Customize Template</a></li>
            <li><a class="step3" href="javascript:;">Review</a></li>
            <li><a class="active step4" href="javascript:;">Recipients</a></li>
            <span class="bar"></span>
          </ul>
        </div>

      </div>
      <!-- email-next -->
      <div class="email-campaign">


        <!-- step1-area -->
        <div class="step1-area">
          <div class="email-deatils">
            <h3>Select recipients</h3>
            <form action="">
              <div class="form-row">
                <div class="form-group col-sm-6 col-md-6 col-lg-4">
                  <label class="con-company currnt list-btn" @click="showRecipients('list')">List
                    <input type="radio" name="recipient_type" value="list"
                      v-model="email_campaign_form.campaign_recipient_type">
                    <span class="checkmark"></span>
                  </label>
                </div>
                <div class="form-group col-sm-6 col-md-6 col-lg-4">
                  <label class="con-company Indivi-btn" @click="showRecipients('individual')">Individual contacts
                    <input type="radio" name="recipient_type" value="individual"
                      v-model="email_campaign_form.campaign_recipient_type">
                    <span class="checkmark"></span>
                  </label>
                </div>
              </div>

                        <!-- list-tab -->
                        <div class="list-tab">
                            <div class="selected-item-list">
                                <span v-for="(Selected, keyIndex) in SelectedCustomersListList" v-bind:key="Selected.id" v-bind:value="Selected.id" :class="'selected-item selected-customer-'+Selected.id">
                                    <button type="button" class="selected-item-button" data-type="list" :data-id="Selected.id" :data-index="keyIndex" title="Remove item" aria-label="Remove item">
                                        <span aria-hidden="true">×</span>
                                    </button>
                                    <span class="selected-item-text">{{ Selected.title }}</span>
                                </span>
                            </div>
                            <div class="selected-item-list add-title" style="position: relative;">
                                <input v-model="email_campaign_form.campaign_search_customer_list" type="text" class="form-control" placeholder="Search Lists..." id="appointment-customers-input" @click="openCustomerListBox('list')" @keyup="searchCustomerListBox('list')" />
                                <input type="hidden" v-model="email_campaign_form.campaign_customers_list" />
                                <div class="appointment-customers-list-box">
                                    <ul class="appointment-customers-list">
                                        <li class="appointment-customers-list-item" v-for="listOption in AllCustomersLists" v-bind:key="listOption.id" v-bind:value="listOption.id">
                                            <a v-if="listOption.status == 0" :class="{'appointment-customers-item':true, 'selected-item-bg':email_campaign_form.campaign_customers_list.includes(listOption.id)}" href="javascript:;" :data-id="listOption.id" :data-name="listOption.title" @click="customerSelectId(listOption.title, listOption.id,'list')">{{ listOption.title }} - [Uploading in Progress]</a>
                                            <a v-else :class="{'appointment-customers-item':true, 'selected-item-bg':email_campaign_form.campaign_customers_list.includes(listOption.id)}" href="javascript:;" :data-id="listOption.id" :data-name="listOption.title" @click="customerSelectId(listOption.title, listOption.id,'list')">{{ listOption.title }}</a>
                                        </li>
                                    </ul>
                                    <span class="appointment-customers-searching" style="display: none;">Searching...</span>
                                    <span class="appointment-customers-notfound" style="display: none;">No Customer found.</span>
                                </div>
                            </div>
                        </div>
                        <!-- list-tab -->

                        <!-- Individual-tab -->
                        <div class="Individual-tab">
                            <div class="selected-item-list">
                                <span v-for="(Selected, keyIndex) in SelectedCustomersList" v-bind:key="Selected.id" v-bind:value="Selected.id" :class="'selected-item selected-customer-'+Selected.id">
                                    <button type="button" class="selected-item-button" data-type="customer" :data-id="Selected.id" :data-index="keyIndex" title="Remove item" aria-label="Remove item">
                                        <span aria-hidden="true">×</span>
                                    </button>
                                    <span class="selected-item-text">{{ Selected.name }}</span>
                                </span>
                            </div>
                            <div class="selected-item-list add-title" style="position: relative;">
                                <input v-model="email_campaign_form.campaign_search_customer_name" type="text" class="form-control" placeholder="Search Customers..." id="appointment-customers-input" @click="openCustomerListBox('customer')" @keyup="searchCustomerListBox('customer')" />
                                <input type="hidden" v-model="email_campaign_form.campaign_customers" />
                                <div class="appointment-customers-list-box">
                                    <ul class="appointment-customers-list">
                                        <li class="appointment-customers-list-item" v-for="Customer in AllCustomers" v-bind:key="Customer.id" v-bind:value="Customer.id">
                                            <a :class="{'appointment-customers-item':true, 'selected-item-bg':email_campaign_form.campaign_customers.includes(Customer.id)}" href="javascript:;" :data-id="Customer.id" :data-name="Customer.first_name+' '+Customer.last_name" @click="customerSelectId(Customer.first_name+' '+Customer.last_name, Customer.id,'customer')">{{ Customer.first_name+' '+Customer.last_name }}</a>
                                        </li>
                                    </ul>
                                    <span class="appointment-customers-searching" style="display: none;">Searching...</span>
                                    <span class="appointment-customers-notfound" style="display: none;">No Customer found.</span>
                                </div>
                            </div>
                        </div>
                        <!-- Individual-tab -->

            </form>
          </div>
        </div>
        <!-- step1-area -->
        <!-- step1-area -->
        <div class="step1-area">
          <div class="email-deatils">
            <h3>Sending options</h3>
            <form action="">

              <div class="form-row">
                <div class="form-group col-md-6 col-lg-3">
                  <label class="con-company currnt send-tab" aria-label="Send now">Send now
                    <input type="radio" name="radio" value="now" v-model="email_campaign_form.campaign_send_option"
                      @click="sendOption('now')" />
                    <span class="checkmark"></span>
                  </label>
                </div>
                <div class="form-group col-md-6 col-lg-4">
                  <label class="con-company sche-tab" aria-label="Later">Schedule for Later
                    <input type="radio" name="radio" value="later" v-model="email_campaign_form.campaign_send_option"
                      @click="sendOption('later')" />
                    <span class="checkmark"></span>
                  </label>
                </div>
                <div class="form-group col-md-6 col-lg-5">
                  <label class="con-company zoon-tab" aria-label="time zones">Schedule based on time zones
                    <input type="radio" name="radio" value="timezone" v-model="email_campaign_form.campaign_send_option"
                      @click="sendOption('timezone')" />
                    <span class="checkmark"></span>
                  </label>
                </div>
              </div>

              <!-- send-now -->
              <div class="send-now">
                <div class="send-info">
                  <p>Your email will be sent immediately.</p>
                </div>

                <div class="send-btna">
                  <a href="javascript:;" @click="handleSubmitCampaign('now')">Send Now</a>
                </div>
              </div>
              <!-- send-now -->


              <!-- sche-later -->
              <div class="sche-later">
                <div class="send-info">
                  <p>Schedule a future time and date to publish your email.</p>
                  <h3>When do you want to send it out?</h3>
                </div>

                <div class="form-row">
                  <div class="form-group col-md-4 col-lg-4">
                    <input type="date" class="form-control" placeholder="Schedule based on time zones"
                      v-model="email_campaign_form.campaign_send_date">

                  </div>
                  <div class="form-group col-md-4 col-lg-4">
                    <input type="time" class="form-control" v-model="email_campaign_form.campaign_send_time">
                  </div>
                  <!-- timezoon -->
                  <div class="timezoon">
                    <div class="form-group col-md-4 col-lg-4">
                      <select class="form-control" v-model="email_campaign_form.campaign_send_timezone">
                        <option value="" selected>Select Timezone</option>
                        <option value="Etc/GMT+12">(GMT-12:00) International Date Line West</option>
                        <option value="Pacific/Midway">(GMT-11:00) Midway Island, Samoa</option>
                        <option value="Pacific/Honolulu">(GMT-10:00) Hawaii</option>
                        <option value="US/Alaska">(GMT-09:00) Alaska</option>
                        <option value="America/Los_Angeles">(GMT-08:00) Pacific Time (US & Canada)</option>
                        <option value="America/Tijuana">(GMT-08:00) Tijuana, Baja California</option>
                        <option value="US/Arizona">(GMT-07:00) Arizona</option>
                        <option value="America/Chihuahua">(GMT-07:00) Chihuahua, La Paz, Mazatlan</option>
                        <option value="US/Mountain">(GMT-07:00) Mountain Time (US & Canada)</option>
                        <option value="America/Managua">(GMT-06:00) Central America</option>
                        <option value="US/Central">(GMT-06:00) Central Time (US & Canada)</option>
                        <option value="America/Mexico_City">(GMT-06:00) Guadalajara, Mexico City, Monterrey</option>
                        <option value="Canada/Saskatchewan">(GMT-06:00) Saskatchewan</option>
                        <option value="America/Bogota">(GMT-05:00) Bogota, Lima, Quito, Rio Branco</option>
                        <option value="US/Eastern">(GMT-05:00) Eastern Time (US & Canada)</option>
                        <option value="US/East-Indiana">(GMT-05:00) Indiana (East)</option>
                        <option value="Canada/Atlantic">(GMT-04:00) Atlantic Time (Canada)</option>
                        <option value="America/Caracas">(GMT-04:00) Caracas, La Paz</option>
                        <option value="America/Manaus">(GMT-04:00) Manaus</option>
                        <option value="America/Santiago">(GMT-04:00) Santiago</option>
                        <option value="Canada/Newfoundland">(GMT-03:30) Newfoundland</option>
                        <option value="America/Sao_Paulo">(GMT-03:00) Brasilia</option>
                        <option value="America/Argentina/Buenos_Aires">(GMT-03:00) Buenos Aires, Georgetown</option>
                        <option value="America/Godthab">(GMT-03:00) Greenland</option>
                        <option value="America/Montevideo">(GMT-03:00) Montevideo</option>
                        <option value="America/Noronha">(GMT-02:00) Mid-Atlantic</option>
                        <option value="Atlantic/Cape_Verde">(GMT-01:00) Cape Verde Is.</option>
                        <option value="Atlantic/Azores">(GMT-01:00) Azores</option>
                        <option value="Africa/Casablanca">(GMT+00:00) Casablanca, Monrovia, Reykjavik</option>
                        <option value="Etc/Greenwich">(GMT+00:00) Greenwich Mean Time : Dublin, Edinburgh, Lisbon,
                          London
                        </option>
                        <option value="Europe/Amsterdam">(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna
                        </option>
                        <option value="Europe/Belgrade">(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague
                        </option>
                        <option value="Europe/Brussels">(GMT+01:00) Brussels, Copenhagen, Madrid, Paris</option>
                        <option value="Europe/Sarajevo">(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb</option>
                        <option value="Africa/Lagos">(GMT+01:00) West Central Africa</option>
                        <option value="Asia/Amman">(GMT+02:00) Amman</option>
                        <option value="Europe/Athens">(GMT+02:00) Athens, Bucharest, Istanbul</option>
                        <option value="Asia/Beirut">(GMT+02:00) Beirut</option>
                        <option value="Africa/Cairo">(GMT+02:00) Cairo</option>
                        <option value="Africa/Harare">(GMT+02:00) Harare, Pretoria</option>
                        <option value="Europe/Helsinki">(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius
                        </option>
                        <option value="Asia/Jerusalem">(GMT+02:00) Jerusalem</option>
                        <option value="Europe/Minsk">(GMT+02:00) Minsk</option>
                        <option value="Africa/Windhoek">(GMT+02:00) Windhoek</option>
                        <option value="Asia/Kuwait">(GMT+03:00) Kuwait, Riyadh, Baghdad</option>
                        <option value="Europe/Moscow">(GMT+03:00) Moscow, St. Petersburg, Volgograd</option>
                        <option value="Africa/Nairobi">(GMT+03:00) Nairobi</option>
                        <option value="Asia/Tbilisi">(GMT+03:00) Tbilisi</option>
                        <option value="Asia/Tehran">(GMT+03:30) Tehran</option>
                        <option value="Asia/Muscat">(GMT+04:00) Abu Dhabi, Muscat</option>
                        <option value="Asia/Baku">(GMT+04:00) Baku</option>
                        <option value="Asia/Yerevan">(GMT+04:00) Yerevan</option>
                        <option value="Asia/Kabul">(GMT+04:30) Kabul</option>
                        <option value="Asia/Yekaterinburg">(GMT+05:00) Yekaterinburg</option>
                        <option value="Asia/Karachi">(GMT+05:00) Islamabad, Karachi, Tashkent</option>
                        <option value="Asia/Calcutta">(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi</option>
                        <option value="Asia/Calcutta">(GMT+05:30) Sri Jayawardenapura</option>
                        <option value="Asia/Katmandu">(GMT+05:45) Kathmandu</option>
                        <option value="Asia/Almaty">(GMT+06:00) Almaty, Novosibirsk</option>
                        <option value="Asia/Dhaka">(GMT+06:00) Astana, Dhaka</option>
                        <option value="Asia/Rangoon">(GMT+06:30) Yangon (Rangoon)</option>
                        <option value="Asia/Bangkok">(GMT+07:00) Bangkok, Hanoi, Jakarta</option>
                        <option value="Asia/Krasnoyarsk">(GMT+07:00) Krasnoyarsk</option>
                        <option value="Asia/Hong_Kong">(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi</option>
                        <option value="Asia/Kuala_Lumpur">(GMT+08:00) Kuala Lumpur, Singapore</option>
                        <option value="Asia/Irkutsk">(GMT+08:00) Irkutsk, Ulaan Bataar</option>
                        <option value="Australia/Perth">(GMT+08:00) Perth</option>
                        <option value="Asia/Taipei">(GMT+08:00) Taipei</option>
                        <option value="Asia/Tokyo">(GMT+09:00) Osaka, Sapporo, Tokyo</option>
                        <option value="Asia/Seoul">(GMT+09:00) Seoul</option>
                        <option value="Asia/Yakutsk">(GMT+09:00) Yakutsk</option>
                        <option value="Australia/Adelaide">(GMT+09:30) Adelaide</option>
                        <option value="Australia/Darwin">(GMT+09:30) Darwin</option>
                        <option value="Australia/Brisbane">(GMT+10:00) Brisbane</option>
                        <option value="Australia/Canberra">(GMT+10:00) Canberra, Melbourne, Sydney</option>
                        <option value="Australia/Hobart">(GMT+10:00) Hobart</option>
                        <option value="Pacific/Guam">(GMT+10:00) Guam, Port Moresby</option>
                        <option value="Asia/Vladivostok">(GMT+10:00) Vladivostok</option>
                        <option value="Asia/Magadan">(GMT+11:00) Magadan, Solomon Is., New Caledonia</option>
                        <option value="Pacific/Auckland">(GMT+12:00) Auckland, Wellington</option>
                        <option value="Pacific/Fiji">(GMT+12:00) Fiji, Kamchatka, Marshall Is.</option>
                        <option value="Pacific/Tongatapu">(GMT+13:00) Nuku'alofa</option>
                      </select>
                    </div>
                  </div>
                  <!-- timezoon -->
                </div>

                <div class="send-btna">
                  <a href="javascript:;" @click="handleSubmitCampaign('later')">Sechedule Now</a>
                </div>
              </div>
              <!-- sche-later -->





            </form>
          </div>
        </div>
        <!-- step1-area -->

      </div>
      <!-- Email campaign -->
    </div>
    <!-- email-setp5 end -->
  </div>
</template>
<style src="@vueform/multiselect/themes/default.css"></style>
<script>
import $ from "jquery";
import { ref, onMounted } from "vue";
import axios from "axios";
import TinyMceEditor from '@tinymce/tinymce-vue';
// import { useRoute } from "vue-router";
import routes from "./../../routes";
// import Multiselect from '@vueform/multiselect';
import "select2";
export default {
  components: {
    TinyMceEditor,
    // Multiselect
  },
  setup() {
    let base_url = "http://api.streamly.com/uploads/";
    let AllTemplates = ref([]);
    let current_Template = 0;
    // const route = useRoute();
    // let multiselectValue = ref([]);
    // let multiselectOptions = ref([]);
    let editor = ref({
        container: "#gjs",
        instance : {},
        html : "",
        template : {
            id: "",
        },
    });

    const loadEditor = () => {
      editor.value.instance = window.grapesjs.init({
        container: editor.value.container,
        height: '900px',
        width: '100%',
        plugins: [
            // 'gjs-preset-webpage',
            'grapesjs-preset-newsletter'
        ],
        storageManager: {
        id: 'gjs-',
        type: 'local',
        options: {
            local: { key: `gjs-template-${editor.value.template.id}` }
        },
        autosave: false,
        storeComponents: true,
        storeStyles: true,
        storeHtml: true,
        storeCss: true,
        },
        deviceManager: {
        devices:
        [
            {
                id: 'desktop',
                name: 'Desktop',
                width: '',
            },
            {
                id: 'tablet',
                name: 'Tablet',
                width: '768px',
                widthMedia: '992px',
            },
            {
                id: 'mobilePortrait',
                name: 'Mobile portrait',
                width: '320px',
                widthMedia: '575px',
            },
        ]
        },
        pluginsOpts: {
            'grapesjs-preset-newsletter':{
                blocks: ['sect100', 'sect50', 'sect30', 'sect37', 'button', 'divider', 'text', 'text-sect', 'image', 'quote', 'link', 'link-block', 'grid-items', 'list-items'],
                block: () => ({}),
                juiceOpts: {},
                cmdOpenImport: 'gjs-open-import-template',
                cmdTglImages: 'gjs-toggle-images',
                cmdInlineHtml: 'gjs-get-inlined-html',
                modalTitleImport: 'Import template',
                modalTitleExport: 'Export template',
                modalLabelImport: '',
                modalLabelExport: '',
                modalBtnImport: 'Import',
                codeViewerTheme: 'hopscotch',
                importPlaceholder: '',
                inlineCss: true,
                cellStyle: {
                    padding: '0',
                    margin: '0',
                    'vertical-align': 'top',
                },
                tableStyle: {
                    height: '150px',
                    margin: '0 auto 10px auto',
                    padding: '5px 5px 5px 5px',
                    width: '100%'
                },
                updateStyleManager: true,
                showStylesOnChange: true,
                showBlocksOnLoad: true,
                useCustomTheme: true,
                textCleanCanvas: 'Are you sure you want to clear the canvas?',
            },
        }
      });
  
      if(editor.value.template.id != "" && editor.value.template.export_template != ""){
          editor.value.instance.BlockManager.add('template_'+editor.value.template.id, {
              label: editor.value.template.id + " : " + editor.value.template.name,
              content: editor.value.template.export_template,
          });
      }
    }
    let company_detail = ref({
      company_name: '',
      company_email: '',
      company_phone: '',
      company_address: '',
    });
    let email_campaign_form = ref({
      campaign_name: '',
      email_subject: '',
      campaign_template: '',
      campaign_search_customer_list: '',
      campaign_search_customer_name: '',
      campaign_customers: [],
      campaign_customers_list: [],
      campaign_recipient_type: '',
      campaign_send_date: '',
      campaign_send_option: '',
      campaign_send_time: '',
      campaign_send_timezone: '',
    });
    let AllCustomersLists = ref([]);
    let AllCustomers = ref([]);
    let SelectedCustomersList = ref([]);
    let SelectedCustomersListList = ref([]);


    const putTemplateValue = async (Id = 0, selected_template = '') => {
      current_Template = Id;
      email_campaign_form.value.campaign_template = selected_template;
    }
    const gotoStep1 = async (param = null) => {
      console.log(param);
      $(".email-step1").show();
      $(".email-step2").hide();
      $(".email-step3").hide();
      $(".email-step4").hide();
      $(".email-step5").hide();
    }
    const gotoStep2 = async (param = null, selected = null) => {
      // console.log(param);
      $(".email-step1").hide();
      $(".email-step2").show();
      $(".email-step3").hide();
      $(".email-step4").hide();
      $(".email-step5").hide();
      if (param == 'next') {
        if (selected == null) {
          current_Template = 0;
          email_campaign_form.value.campaign_template = '';
        }
      }
    }
    const gotoStep3 = async (param = null) => {
      console.log(param);
      $(".email-step1").hide();
      $(".email-step2").hide();
      $(".email-step3").show();
      $(".email-step4").hide();
      $(".email-step5").hide();
    }
    const gotoStep4 = async (param = null) => {
      console.log(param);
      $(".email-step1").hide();
      $(".email-step2").hide();
      $(".email-step3").hide();
      $(".email-step4").show();
      $(".email-step5").hide();
    }

    const showRecipients = async (param = null) => {
      // console.log(param);
      if (param == 'list') {
        $(".list-tab").show();
        $(".Individual-tab").hide();
      } else {
        $(".list-tab").hide();
        $(".Individual-tab").show();
      }
    }
    const sendOption = async (param = null) => {
      // console.log(param);
      if (param == 'now') {
        $(".send-now").show();
        $(".sche-later").hide();
        $(".timezoon").hide();
      } else if (param == 'later') {
        $(".send-now").hide();
        $(".sche-later").show();
        $(".timezoon").hide();
      } else {
        $(".sche-later").show();
        $(".timezoon").show();
      }
    }
    const getCompanyDetails = async () => {
      await axios
        .get(
          axios.defaults.baseUrl + "profile",
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem('auth_token'),
            },
          }
        )
        .then((response) => {
          // console.log(response.data);
          company_detail.value.company_name = response.data.data.company_name;
          company_detail.value.company_email = response.data.data.company_email;
          company_detail.value.company_phone = response.data.data.company_phone;
          company_detail.value.company_address = response.data.data.company_address;
          // console.log(company_detail.value);
          // AllTemplates.value = response.data.data.allTemplates;
          // datatableInit();
        })
        .catch((error) => {
          console.log(error);
          window.showHideMainLoader(false);
          // showToast(error.response.data.status, error.response.data.message);
          return false;
        });
    }

    const getEmailTemplates = async () => {
      const getFormData = new FormData();
      // getFormData.append('list_id', list_id);
      getFormData.append('dealer_id', localStorage.getItem('dealer_id'));
      getFormData.append('location_id', localStorage.getItem('location_id'));
      await axios
        .post(axios.defaults.baseUrl + "templates/email",
          getFormData,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem('auth_token'),
            },
          }
        )
        .then((response) => {
          AllTemplates.value = response.data.data.allTemplates;
          // datatableInit();
        })
    }
    const getCustomersList = async () => {
      const getFormData = new FormData();
      getFormData.append('dealer_id', localStorage.getItem('dealer_id'));
      getFormData.append('location_id', localStorage.getItem('location_id'));
      await axios
        .post(axios.defaults.baseUrl + "customers/lists",
          getFormData,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem('auth_token'),
            },
          }
        )
        .then((response) => {
          AllCustomersLists.value = response.data.data.allCustomersLists;
          // this.multiselectValue = [
          //    { value: 'batman', label: 'Nob' },
          //    { value: 'robin', label: 'Robin' },
          //    { value: 'joker', label: 'Joker' },
          //  ];
        });
    }
    const getCustomers = async (list_id = null) => {
      const getFormData = new FormData();
      getFormData.append('list_id', list_id);
      getFormData.append('dealer_id', localStorage.getItem('dealer_id'));
      getFormData.append('location_id', localStorage.getItem('location_id'));
      await axios
        .post(axios.defaults.baseUrl + "customers",
          getFormData,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem('auth_token'),
            },
          }
        )
        .then((response) => {
          AllCustomers.value = response.data.data.allCustomers;
          // datatableInit();
        })
    }
    const searchCustomerListBox = async (type = null) => {
            let search_param = '';
            let currentUrl = '';
            if (type == 'customer') {
                currentUrl = "customers/appointment";
                search_param = email_campaign_form.value.campaign_search_customer_name;
            } else {
                currentUrl = "customers/lists";
                search_param = email_campaign_form.value.campaign_search_customer_list;
            }

            $('.appointment-customers-notfound').hide();
            $('.appointment-customers-list').hide();
            $('.appointment-customers-searching').show();
            const getFormData = new FormData();

            getFormData.append('dealer_id', localStorage.getItem('dealer_id'));
            getFormData.append('location_id', localStorage.getItem('location_id'));
            if (search_param.length >= 2) {
                getFormData.append('search_param', search_param);
            }
            // console.log(currentUrl);
            await axios
                .post(axios.defaults.baseUrl + currentUrl,
                    getFormData, {
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem('auth_token'),
                        },
                    }
                )
                .then((response) => {
                    if (type == 'customer') {
                        AllCustomers.value = response.data.data.allCustomers;
                    } else {
                        AllCustomersLists.value = response.data.data.allCustomersLists;
                    }

                    // console.log(response.data.data.allCustomers);
                    $('.appointment-customers-searching').hide();
                    if (!response.data.data.no_customers) {
                        $('.appointment-customers-list').show();
                    } else {
                        $('.appointment-customers-notfound').show();
                    }
                })
        }
        const openCustomerListBox = async () => {
            if (!$('.appointment-customers-list-box').is(':visible')) {
                $('.appointment-customers-list-box').show();
            } else {
                $('.appointment-customers-list-box').hide();
            }
        }

    const handleSubmitCampaign = async (param = null) => {
      console.log(param);
      // console.log(email_campaign_form.value.campaign_customers_list);
      const getFormData = new FormData();
      // getFormData.append('list_id', list_id);
      getFormData.append('dealer_id', localStorage.getItem('dealer_id'));
      getFormData.append('location_id', localStorage.getItem('location_id'));
      getFormData.append('title', email_campaign_form.value.campaign_name);
      getFormData.append('email_subject', email_campaign_form.value.email_subject);
      getFormData.append('campaign_template', email_campaign_form.value.campaign_template);
      getFormData.append('campaign_recipient_type', email_campaign_form.value.campaign_recipient_type);
      getFormData.append('campaign_customers_list', email_campaign_form.value.campaign_customers_list);
      getFormData.append('campaign_customers', email_campaign_form.value.campaign_customers);
      getFormData.append('campaign_send_option', email_campaign_form.value.campaign_send_option);
      getFormData.append('campaign_send_date', email_campaign_form.value.campaign_send_date);
      getFormData.append('campaign_send_time', email_campaign_form.value.campaign_send_time);
      getFormData.append('campaign_send_timezone', email_campaign_form.value.campaign_send_timezone);
      await axios
        .post(axios.defaults.baseUrl + "campaigns/email/add",
          getFormData,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem('auth_token'),
            },
          }
        )
        .then((response) => {
          console.log(response);
          // AllCustomers.value = response.data.data.allCustomers;
          // datatableInit();
          routes.push("/email-campaign");
        })
    }
    const variable_inupt = (event) => {
      // console.log(event.target.innerHTML);
      console.log(company_detail.value);
      let thisVal = event.target.innerHTML;
      // let bookmark = TinyMceEditor.activeEditor.selection.getBookmark();
      // let description = TinyMceEditor.getInstanceById('editortiny').getContent();
      // let description = TinyMceEditor.activeEditor.getContent();
      if (thisVal == '%companyname%') {
        thisVal = company_detail.value.company_name;
      }
      if (thisVal == '%companyemail%') {
        thisVal = company_detail.value.company_email;
      }
      if (thisVal == '%companyphone%') {
        thisVal = company_detail.value.company_phone;
      }
      if (thisVal == '%companyaddress%') {
        thisVal = company_detail.value.company_address;
      }
      // console.log(thisVal);

      let description = email_campaign_form.value.campaign_template + thisVal;
      // console.log(description);
      email_campaign_form.value.campaign_template = description;
      // setTimeout(function () {
      //   countChar();
      // }, 300);

    }
    const countChar = (event = null) => {
      console.log(event);
      let maxLength = 320;
      let smsLength = 160;
      // let messageLength = event.target.value.length;
      let template_textarea = $('#template_textarea').val();
      // console.log(template_textarea);
      let messageLength = template_textarea.length;
      if (messageLength > 160) {
        smsLength = 320;
      }
      if (messageLength >= maxLength) {
        messageLength = template_textarea.substring(0, maxLength);
      } else {
        $('#charNum').text(messageLength + '/' + smsLength);
      }
      // $('#charNum').text(messageLength+'/'+maxCount);
    }
    const addClass = (elementName, className) => {
      $(elementName).addClass(className);
    }

    const removeClass = (elementName, className) => {
      $(elementName).removeClass(className);
    }
    const showToast = (toastIcon, toastMessage) => {
      window.toast.fire({
        icon: toastIcon,
        title: toastMessage,
      });
    }
    // Open Modal
    const openModal = () => {
      $("#featureNotAvailable").modal("show");
    };

    // Close Modal
    const closeModal = () => {
      $("#featureNotAvailable").modal("hide");
    };
    const customerSelectId = async (selected_name = null, selected_id = null, type = null) => {
            let arrIndex = -1;
            if (type == 'customer') {
                arrIndex = email_campaign_form.value.campaign_customers.findIndex(thisItem => thisItem == selected_id);
            } else {
                arrIndex = email_campaign_form.value.campaign_customers_list.findIndex(thisItem => thisItem == selected_id);
            }
            if (arrIndex < 0) {
                let newArray = [];
                newArray['id'] = selected_id;
                if (type == 'customer') {
                    newArray['name'] = selected_name;
                    SelectedCustomersList.value.push(newArray);
                    email_campaign_form.value.campaign_customers.push(selected_id);
                } else {
                    newArray['title'] = selected_name;
                    SelectedCustomersListList.value.push(newArray);
                    email_campaign_form.value.campaign_customers_list.push(selected_id);
                }
            }
            email_campaign_form.value.campaign_search_customer_name = '';
            email_campaign_form.value.campaign_search_customer_list = '';
            searchCustomerListBox(type);
        }
        $(document).on('click', '.selected-item-button', function (e) {
            let selected_id = e.target.parentElement.getAttribute('data-id');
            let selected_type = e.target.parentElement.getAttribute('data-type');
            let selected_index = e.target.parentElement.getAttribute('data-index');
            let arrIndex = -1;
            if (selected_type == 'customer') {
                SelectedCustomersList.value.splice(selected_index, 1);
                arrIndex = email_campaign_form.value.campaign_customers.findIndex(thisItem => thisItem == selected_id);
                email_campaign_form.value.campaign_customers.splice(arrIndex, 1);
            } else {
                SelectedCustomersListList.value.splice(selected_index, 1);
                arrIndex = email_campaign_form.value.campaign_customers_list.findIndex(thisItem => thisItem == selected_id);
                email_campaign_form.value.campaign_customers_list.splice(arrIndex, 1);
            }
        });
    /** /
    const fetchLanguages = () => {
      // let multiselectValues = [];
      // let Lists = AllCustomersLists.value;
      // console.log(AllCustomersLists.value);
      // $.each(Lists, function (key, CustomersList) {
      //   console.log(CustomersList);
      //   key = key + 1;
      //   multiselectValues.push({ 'value': CustomersList.title, 'label': CustomersList.id });
      // });
      let multiselectValues = [
        { value: 'batman', label: 'Nob' },
        { value: 'robin', label: 'Robin' },
        { value: 'joker', label: 'Joker' },
      ];
      return multiselectValues;

    }
    /**/
    /**/
     const fetchLanguages = async (query) => {
      //  alert();
       console.log(query);
       const getFormData = new FormData();
       getFormData.append('dealer_id', localStorage.getItem('dealer_id'));
       getFormData.append('location_id', localStorage.getItem('location_id'));
       await axios
         .post(axios.defaults.baseUrl + "customers/lists",
           getFormData,
           {
             headers: {
               Authorization: "Bearer " + localStorage.getItem('auth_token'),
             },
           }
         )
         .then((response) => {
           console.log(response);
           // AllCustomersLists.value = response.data.data.allCustomersLists;
           // const data = response.data.data.allCustomersLists.json(); // Here you have the data that you need
           // return data.results.map((item) => {
           //   return { value: item.id, label: item.title }
           // });
          //  multiselectOptions = [
          //    { value: 'batman', label: 'Nob' },
          //    { value: 'robin', label: 'Robin' },
          //    { value: 'joker', label: 'Joker' },
          //  ];
          //  return multiselectValues;
         });
     }
     /**/
    /*
         const fetchLanguages = async (query) => {
           // From: https://www.back4app.com/database/paul-datasets/list-of-all-programming-languages/get-started/javascript/rest-api/fetch?objectClassSlug=dataset
     
           let where = ''
     
           if (query) {
             where = '&where=' + encodeURIComponent(JSON.stringify({
               "ProgrammingLanguage": {
                 "$regex": `${query}|${query.toUpperCase()}|${query[0].toUpperCase() + query.slice(1)}`
               }
             }))
           }
     
           const response = await fetch(
             'https://parseapi.back4app.com/classes/All_Programming_Languages?limit=9999&order=ProgrammingLanguage&keys=ProgrammingLanguage' + where,
             {
               headers: {
                 'X-Parse-Application-Id': 'XpRShKqJcxlqE5EQKs4bmSkozac44osKifZvLXCL', // This is the fake app's application id
                 'X-Parse-Master-Key': 'Mr2UIBiCImScFbbCLndBv8qPRUKwBAq27plwXVuv', // This is the fake app's readonly master key
               }
             }
           );
     
           const data = await response.json(); // Here you have the data that you need
     
           return data.results.map((item) => {
             return { value: item.ProgrammingLanguage, label: item.ProgrammingLanguage }
           })
         };
     */
    onMounted(() => {
      $(".select2").select2({
        width: '100%',
        placeholder: 'Select',
      });
      $(".email-step1").show();
      $(".email-step2").hide();
      $(".email-step3").hide();
      $(".email-step4").hide();
      $(".email-step5").hide();
      $(".list-tab").hide();
      $(".Individual-tab").hide();
      $(".send-now").hide();
      $(".sche-later").hide();
      $(".timezoon").hide();
      getEmailTemplates();
      getCustomersList();
      getCustomers();
      getCompanyDetails();
      window.showHideMainLoader(false);
      loadEditor();
    });

    return {
      openModal,
      closeModal,
      base_url,
      AllTemplates,
      putTemplateValue,
      gotoStep1,
      gotoStep2,
      gotoStep3,
      gotoStep4,
      showRecipients,
      current_Template,
      email_campaign_form,
      AllCustomersLists,
      AllCustomers,
      sendOption,
      handleSubmitCampaign,
      getCompanyDetails,
      company_detail,
      countChar,
      variable_inupt,
      removeClass,
      addClass,
      showToast,
      fetchLanguages,
      // multiselectValue,
      // multiselectOptions,


      openCustomerListBox,
      searchCustomerListBox,
      customerSelectId,
      SelectedCustomersList,
      SelectedCustomersListList,

    };
  },
};
</script>