<template>
    <!-- header-area -->
    <div class="header-area">
        <div class="fixed-header">
            <div class="row">
                <div class="col-6">
                    <div class="title">
                        <h2> <span class="mob">

                                <img class="img-fluid white-menu" src="@/assets/img/menu.svg" alt="image title">
                                <img class="img-fluid blue-menu" src="@/assets/img/menu-blue.svg" alt="image title">

                            </span>
                            <span class="menuwidth">
                                <img class="img-fluid white-menu" src="@/assets/img/menu.svg" alt="image title">
                                <img class="img-fluid blue-menu" src="@/assets/img/menu-blue.svg" alt="image title">
                            </span> {{ business_name }}
                            <span class="locations">
                                <img v-if="user_type != 'superadmin'" class="img-fluid" src="@/assets/img/Pin.svg" alt="">
                                <span v-if="user_type != 'superadmin'" class="locaname">{{ location }} </span>
                            </span>
                        </h2>
                    </div>
                </div>
                <div class="col-6">
                    <div class="users">
                        <ul>
                            <li v-if="user_type != 'superadmin'" style="display:none;"><a class="uploadcontact" href="#" data-toggle="modal" data-target="#upload_contacts">Upload
                                    customers</a></li>
                            <li v-if="user_type != 'superadmin'" style="display:none;"><a class="uploadcontact" href="#" data-toggle="modal" data-target="#create_contact">Create a
                                    customer</a></li>
                            <li v-if="user_type != 'superadmin'" style="display:none;"><a class="sendInvite" href="#" data-toggle="modal" data-target="#send_review_modal"><img
                                        class="img-fluid" src="@/assets/img/plane.svg" alt="send review link icon"> Send
                                    Review Invite</a></li>
                            <li>
                                <div class="navbar dropdown">
                                    <a class="userbtn dropdown-toggle" href="#" role="button" id="dropdownMenuLink"
                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> {{ user_name }} 
                                        <span class="topheader-username">{{ user_name_initials }}</span>
                                    </a>

                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                        <RouterLink v-if="user_type != 'superadmin'" :to="{ name: 'Settings' }" class="dropdown-item">Settings</RouterLink>
                                        <a href="#" class="dropdown-item" @click.prevent="props.Logout">logout</a>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- header-area -->
</template>
<style>
span.topheader-username {
    margin-left: 15px;
    background: #EAE4FF;
    font-weight: 500;
    font-size: 14px;
    line-height: 32px;
    text-align: center;
    color: #6046FE;
    height: 32px;
    width: 32px;
    border-radius: 50%;
}
</style>
<script setup>
import { useAuthUserStore } from "../stores/auth";
import { storeToRefs } from "pinia";
import { defineProps } from "vue";
const authStore = useAuthUserStore();
const user_type = localStorage.getItem("user_type");
const location = localStorage.getItem("location");
const business_name = localStorage.getItem("business_name");
const user_name = localStorage.getItem("first_name")+' '+localStorage.getItem("last_name");
const user_name_initials = localStorage.getItem("user_name_initials");
// const user_type = window.userRoleType;
    // console.log(user_type);
const { auth } = storeToRefs(authStore)
console.log(auth.user);

const props = defineProps({
    Logout: Function,
    RouteObject: Object
})


</script>


